<template>
  <div class="lesson-edit-page w-100">
    <b-overlay :show="loading">
      <basic-subheader>
        <template v-slot:actions>
          <b-button class="btn ml-3" type="button" @click="onClickReturnButton">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
            </span>
            Hủy
          </b-button>
          <b-button
            v-if="!isCreateMode && form.status && form.status.value == 1"
            class="btn btn-inactive ml-3"
            type="button"
            @click="showPopupChangeStatus(0)"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
            </span>
            Inactive
          </b-button>
          <b-button
            v-if="!isCreateMode && form.status && form.status.value != 1"
            class="btn btn-active ml-3"
            type="button"
            @click="showPopupChangeStatus(1)"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
            </span>
            Active
          </b-button>
          <b-button
            class="btn btn-success ml-3"
            type="submit"
            form="lesson-edit-form"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu lại
          </b-button>
        </template>
      </basic-subheader>
      <b-container fluid class="lesson-edit-page__body mb-6 mt-6">
        <b-row>
          <b-col cols="6">
            <div class="card card-custom gutter-b lesson-edit-page__body__card">
              <div class="card-body mt-0">
                <form id="lesson-edit-form" @submit.prevent="handleValidate">
                  <b-row>
                    <b-col>
                      <div class="image-upload">
                        <v-file-input
                          hide-input
                          v-model="file"
                          accept="image/png, image/jpeg"
                          class="icon-edit"
                          @change="onUpload"
                          prepend-icon="mdi-pencil-outline"
                          truncate-length="15"
                        ></v-file-input>
                        <v-img :src="preview" :aspect-ratio="1 / 1"></v-img>
                      </div>
                      <div class="error text-error">
                        <p v-if="!isImageValid" class="mb-1">
                          Kích thước ảnh tối đa nhỏ hơn 512kb
                        </p>
                        <p v-if="!isImage" class="mb-1">
                          Định dạng ảnh phải là JPG hoặc PNG
                        </p>
                      </div>
                      <label
                        class="d-flex flex-column align-items-center justify-content-center"
                      >
                        <span
                          class="mb-1"
                          style="font-weight: bold; font-size: 16px"
                          >Hình ảnh nhóm (tỷ lệ 1:1)</span
                        >
                        <span class="d-block"
                          >Tối đa 512kb, kích thước đề xuất 320:320px</span
                        >
                      </label>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-input
                        :required="true"
                        label="Mã bài học"
                        name="code"
                        placeholder="---Nhập mã bài học---"
                        :value.sync="form.code"
                        :state="validateState('code')"
                        :invalidFeedback="errors.first('code')"
                        v-validate="'required|alpha_num'"
                        data-vv-as="Mã bài học"
                        maxlength="50"
                      />
                    </b-col>
                    <b-col>
                      <basic-select
                        :required="true"
                        label="Trạng thái"
                        placeholder="---Chọn trạng thái---"
                        disabled
                        name="status"
                        :solid="false"
                        :value.sync="form.status"
                        :options="stateOptions"
                        value-label="text"
                        track-by="value"
                        :state="validateState('status')"
                        :invalidFeedback="errors.first('status')"
                        v-validate="'required'"
                        data-vv-as="Trạng thái"
                        @update:value="selected($event, 'status', stateOptions)"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-input
                        :required="true"
                        label="Tên bài học"
                        name="name"
                        placeholder="---Nhập tên bài học---"
                        :value.sync="form.name"
                        :state="validateState('name')"
                        :invalidFeedback="errors.first('name')"
                        v-validate="'required'"
                        data-vv-as="Tên bài học"
                        maxlength="250"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <basic-text-area
                        label="Nội dung giới thiệu"
                        name="description"
                        placeholder="Nhập nội dung giới thiệu bài học"
                        :value.sync="form.description"
                        maxlength="200"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <basic-select
                        :required="true"
                        label="Chủ đề"
                        class="lesson-edit-page__dropdown-filter"
                        placeholder="---Chọn chủ đề---"
                        name="module"
                        :solid="false"
                        :value.sync="form.module"
                        :options="moduleOptions"
                        value-label="text"
                        track-by="value"
                        :state="validateState('module')"
                        :invalidFeedback="errors.first('module')"
                        v-validate="'required'"
                        data-vv-as="Chủ đề"
                        @update:value="
                          selected($event, 'module', moduleOptions)
                        "
                      />
                    </b-col>
                    <b-col cols="6">
                      <basic-select
                        :required="true"
                        label="Loại bài học"
                        placeholder="---Chọn loại bài học ---"
                        name="type"
                        :solid="false"
                        :value.sync="form.type"
                        :options="typeOptions"
                        value-label="text"
                        track-by="value"
                        :state="validateState('type')"
                        :invalidFeedback="errors.first('type')"
                        v-validate="'required'"
                        data-vv-as="Loại bài học"
                        @update:value="selected($event, 'type', typeOptions)"
                        @input="selectedTypeLesson"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <basic-select
                        :required="!isOptionalMode"
                        label="Cấp độ"
                        class="lesson-edit-page__dropdown-filter"
                        placeholder="---Chọn cấp độ---"
                        name="level"
                        :solid="false"
                        :value.sync="form.level"
                        :options="levelOptions"
                        value-label="text"
                        track-by="value"
                        :state="validateState('level')"
                        :invalidFeedback="errors.first('level')"
                        v-validate="{ required: !isOptionalMode }"
                        data-vv-as="Cấp độ"
                        @update:value="selected($event, 'level', levelOptions)"
                      />
                    </b-col>
                    <b-col cols="6">
                      <basic-select
                        :required="!isOptionalMode"
                        label="Thứ tự học"
                        name="order"
                        placeholder="---Chọn thứ tự học thỏa mãn---"
                        :options="orders"
                        :value.sync="form.order"
                        value-label="text"
                        track-by="value"
                        :state="validateState('order')"
                        :invalidFeedback="errors.first('order')"
                        v-validate="{ required: !isOptionalMode }"
                        data-vv-as="Thứ tự học"
                        @update:value="selected($event, 'order', orders)"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-checkbox v-model="form.activeRating">
                        Cho phép đánh giá
                      </b-checkbox>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label>Từ khóa</label>
                      <multiselect
                        :options="tagOptions"
                        placeholder="Tìm kiếm hoặc tạo keyword mới"
                        tag-placeholder="Thêm từ khóa"
                        select-label=""
                        selected-label="Đã chọn"
                        deselect-label="Bỏ chọn"
                        label="text"
                        track-by="value"
                        v-model="form.tags"
                        multiple
                        taggable
                        @tag="onAddTag"
                      />
                    </b-col>
                  </b-row>
                </form>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <b-overlay :show="loadingSection" class="h-100">
              <div
                class="card card-custom gutter-b lesson-edit-page__body__card"
              >
                <div class="card-body mt-0" v-if="isQuizLevelMode">
                  <div class="lesson-edit-page__body__card__quiz">
                    <div class="lesson-edit-page__body__card__quiz__title">
                      <span>Tổng số câu hỏi thỏa mãn: </span>
                      <span>{{ form.questionNum }}</span>
                    </div>
                    <basic-select
                      :required="true"
                      label="Số câu hỏi"
                      name="questionNum"
                      placeholder="---Chọn số câu hỏi---"
                      :options="getQuestionNumOptions(form.questionNum)"
                      :value.sync="form.selectedQuestionNum"
                      value-label="text"
                      track-by="value"
                      :state="validateState('questionNum')"
                      :invalidFeedback="errors.first('questionNum')"
                      v-validate="'required'"
                      data-vv-as="Số câu hỏi"
                      @update:value="
                        selected(
                          $event,
                          'selectedQuestionNum',
                          getQuestionNumOptions(form.questionNum),
                        )
                      "
                    />
                    <basic-input
                      name="minCompletePercent"
                      label="% tối thiểu để hoàn thành bài học"
                      placeholder="Nhập số % thoả mãn"
                      :required="true"
                      :value.sync="form.minCompletePercent"
                      :state="validateState('minCompletePercent')"
                      :invalidFeedback="errors.first('minCompletePercent')"
                      v-validate="'required|max_value:100'"
                      data-vv-as="% tối thiểu"
                      maxlength="3"
                      input-number
                    />
                    <div class="d-flex justify-content-end">
                      <b-button
                        class="btn lesson-edit-page__body__card__quiz__btn-info ml-2"
                        :disabled="!form.selectedQuestionNum"
                        type="button"
                        @click="onClickPreviewQuizButton"
                      >
                        <span class="svg-icon">
                          <inline-svg
                            src="/media/svg/icons/Neolex/Basic/eye.svg"
                          />
                        </span>
                        Xem trước
                      </b-button>
                    </div>
                  </div>
                </div>
                <div
                  class="card-body mt-0"
                  v-else-if="isMandatoryMode || isOptionalMode"
                >
                  <b-row>
                    <b-col class="d-flex justify-content-end">
                      <b-button
                        class="btn btn-success ml-2"
                        type="button"
                        @click="onClickAddLessonSection"
                      >
                        <span class="svg-icon">
                          <inline-svg
                            src="/media/svg/icons/Neolex/Basic/plus.svg"
                          />
                        </span>
                        Thêm học phần
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div ref="sortableSections">
                        <lesson-section
                          v-for="(section, index) in lessonSections"
                          class="sortable"
                          :key="`${section.code}-${$uuid.v1()}`"
                          :section="section"
                          @view="onClickViewLessonSection"
                          @edit="onClickEditLessonSection(section, index)"
                          @delete="onClickDeleteLessonSection(section, index)"
                          @change-status="changeSectionState"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="card-body mt-0" v-else />
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
    <lesson-section-edit
      :section="selectedSection"
      :type="modalType"
      :lessionId="lessonId"
      @edit="callbackEditLessonSection"
      @create="callbackCreateLessonSection"
      @change-status="getLessonSections"
    />
    <lesson-section-detail
      :section="viewedSection"
      @view="callbackViewLessonSection"
    />
    <lesson-quiz-preview
      id="lesson-quiz-review"
      title="Bài học Quiz level"
      :question-list="form.questionList"
      :question-num="form.selectedQuestionNum && form.selectedQuestionNum.value"
    />
  </div>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
import Sortable from 'sortablejs';
import Multiselect from 'vue-multiselect';
import differenceWith from 'lodash/differenceWith';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';

import LessonSection from './LessonSection';
import LessonSectionEdit from './LessonSectionEdit';
import LessonSectionDetail from './LessonSectionDetail';
import LessonQuizPreview from './LessonQuizPreview';
import uniqBy from 'lodash/uniqBy';
import { CONTAINER_FILE_NAME } from '../../../core/plugins/constants';

export default {
  name: 'LessonEdit',
  components: {
    LessonQuizPreview,
    LessonSection,
    LessonSectionEdit,
    LessonSectionDetail,
    Multiselect,
  },
  data() {
    return {
      url: {
        prepare: '/Lesson/PrepareCreateUpdateFormItem',
        create: '/Lesson',
        edit: '/Lesson',
        detail: '/Lesson',
        delete: '/Lesson/{id}',
        changeState: '/Lesson/{id}/State',
        prepareSection: '/LessonSection/PrepareCreateUpdateFormItem',
        createSection: '/LessonSection',
        editSection: '/LessonSection',
        detailSection: '/LessonSection',
        deleteSection: '/LessonSection',
        getQuiz: '/Quiz/SearchAvailableLessonQuiz',
        changeSectionState: '/LessonSection/{id}/State',
      },
      file: null,
      isImageValid: true,
      isImage: true,
      preview: null,
      loading: false,
      stateOptions: [],
      moduleOptions: [],
      levelOptions: [],
      typeOptions: [],
      tagOptions: [],
      orderOptions: [],
      form: {
        code: null,
        coverId: null,
        status: null,
        name: null,
        description: null,
        module: null,
        type: null,
        level: null,
        order: null,
        activeRating: false,
        tags: [],
        minCompletePercent: null,
        questionNum: 0,
        selectedQuestionNum: null,
        questionList: [],
      },
      lessonSections: [],
      selectedSection: {},
      viewedSection: {},
      selectedSectionIndex: 0,
      modalType: null,
      orders: [],
      originalTags: [],
      newTagsOptions: [],
      originalQuestionList: [],
      originalLessonSections: [],
      loadingSection: false,
    };
  },
  watch: {
    'form.level': {
      handler(level) {
        this.form.questionNum = 0;
        this.form.selectedQuestionNum = null;
        if (level) {
          this.getQuizList(level.value).then((res) => {
            this.form.questionNum = res.data?.length || 0;
          });
        }
      },
    },
    'form.selectedQuestionNum': {
      handler(num) {
        let level = this.form.level?.value;
        if (level && num) {
          this.getQuizList(level, num.value).then((res) => {
            let data = res.data;
            this.form.questionList = [...data];
          });
        }
      },
    },
  },
  computed: {
    lessonId() {
      return this.$route.params.id;
    },
    isCreateMode() {
      return this.$route.name === 'lesson_create' ? true : false;
    },
    isCopyMode() {
      return this.$route.params.type === MODAL_TYPE.COPY;
    },
    isQuizLevelMode() {
      return this.form.type && this.form.type.value == 3;
    },
    isOptionalMode() {
      return this.form.type && this.form.type.value == 2;
    },
    isMandatoryMode() {
      return this.form.type && this.form.type.value == 1;
    },
    isActiveAllSections() {
      return (
        this.lessonSections.length &&
        this.lessonSections.filter((el) => el.status !== 1).length <= 0
      );
    },
    showBtnActive() {
      if (
        this.form.status &&
        ['0', '2'].includes(this.form.status.value) &&
        (this.isActiveAllSections || this.isQuizLevelMode)
      )
        return true;
      return false;
    },
  },
  async mounted() {
    await this.loadPrepareData();
    if (this.isMandatoryMode || this.isOptionalMode) {
      new Sortable(this.$refs.sortableSections, {
        draggable: '.sortable',
        handle: '.sortable',
        onEnd: this.dragReorder,
      });
    }
    if (this.isCreateMode) {
      this.selected(2, 'status', this.stateOptions);
    }
    if (this.isCopyMode) {
      this.handleCopy();
    }
  },
  methods: {
    moveToListPage() {
      this.$router.push({
        name: 'lesson',
      });
    },
    openLessonQuizPreviewModal() {
      this.$bvModal.show('lesson-quiz-review');
    },
    openLessonSectionEditModal() {
      this.$bvModal.show('lesson-section-edit');
    },
    openLessonSectionDetailModal() {
      this.$bvModal.show('lesson-section-detail');
    },
    dragReorder(e) {
      let draggedItem = this.lessonSections[e.oldIndex];
      if (this.lessonId) {
        let targetItem = this.lessonSections[e.newIndex];
        this.changeOrder(draggedItem.order, targetItem.order, draggedItem.id);
      }
      this.lessonSections = this.swapArrayLocs(
        this.lessonSections,
        e.oldIndex,
        e.newIndex,
      );
    },
    async changeOrder(oldOrder, newOrder, id) {
      try {
        this.loadingSection = true;
        const { meta, error } = await this.$api.put('LessonSection/Order', {
          lessonId: this.lessonId,
          lessonSectionId: id,
          current: oldOrder,
          new: newOrder,
        });
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loadingSection = false;
      }
    },
    handleCopy() {
      this.lessonSections.forEach((section) => {
        // section.code = null;
        section.id = null;
      });
    },
    onClickPreviewQuizButton() {
      this.openLessonQuizPreviewModal();
    },
    async callbackCreateLessonSection(section) {
      this.loadingSection = true;
      if (this.$route.name === 'lesson_edit') {
        let imageId = section.imageFile
          ? await this.uploadImage(section.imageFile)
          : null;
        let videoUrl = section.fileVideo
          ? await this.uploadVideo(section.fileVideo)
          : null;
        let audioUrl = section.fileAudio
          ? await this.uploadAudio(section.fileAudio)
          : null;
        let sectionParams = {
          code: section.code,
          name: section.name,
          lessonId: this.lessonId,
          ...(imageId && { imageId: imageId }),
          imageTitle: section.imageTitle,
          type: section.type.value,
          status: section.status.value,
          firstContent: section.firstContent,
          secondContent: section.secondContent,
          videoAddressLink: videoUrl || section.videoUrl,
          videoDescription: section.videoTitle,
          audioAddressLink: audioUrl || section.audioUrl,
          audioDescription: section.audioTitle,
          quizLessonSections: this.getQuizParams(
            section.originalQuestionList,
            section.questionList,
          ),
          linkType: section.linkType
          // classify: Number(this.form.type.value),
        };
        const { error } = await this.$api.post(
          this.url.createSection,
          sectionParams,
        );

        if (error) {
          this.loadingSection = false;
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }

        await this.getLessonSections();
      } else {
        this.lessonSections.unshift({
          ...section,
          status: section.status.value,
          type: section.type.value,
          isNew: true,
        });
      }
      this.loadingSection = false;
    },
    async callbackEditLessonSection(section) {
      console.log(section)
      this.loadingSection = true;
      this.lessonSections.splice(this.selectedSectionIndex, 1, {
        ...section,
        status: section.status.value,
        type: section.type.value,
      });
      if (section.id) {
        let imageId = section.imageFile
          ? await this.uploadImage(section.imageFile)
          : null;
        let videoUrl = section.fileVideo
          ? await this.uploadVideo(section.fileVideo)
          : null;
        let audioUrl = section.fileAudio
          ? await this.uploadAudio(section.fileAudio)
          : null;
        let sectionParams = {
          code: section.code,
          name: section.name,
          lessonId: this.lessonId,
          ...(imageId && { imageId: imageId }),
          imageTitle: section.imageTitle,
          type: section.type.value,
          status: section.status.value,
          firstContent: section.firstContent,
          secondContent: section.secondContent,
          videoAddressLink: videoUrl || section.videoUrl,
          videoDescription: section.videoTitle,
          audioAddressLink: audioUrl || section.audioUrl,
          audioDescription: section.audioTitle,
          quizLessonSections: this.getQuizParams(
            section.originalQuestionList,
            section.questionList,
          ),
          linkType: section.linkType
        };
        await this.$api.put(
          `${this.url.editSection}/${section.id}`,
          sectionParams,
        );
        await this.getLessonSections();
        this.loadingSection = false;
      }
    },
    onClickEditLessonSection(section, index) {
      this.modalType = MODAL_TYPE.EDIT;
      this.selectedSection = { ...section };
      this.selectedSectionIndex = index;
      this.openLessonSectionEditModal();
    },
    onClickViewLessonSection(section) {
      this.loading = true;
      this.viewedSection = { ...section };
    },
    callbackViewLessonSection() {
      this.loading = false;
      this.openLessonSectionDetailModal();
    },
    async onClickDeleteLessonSection(section, index) {
      if (section.id) {
        await this.$api.delete(`${this.url.deleteSection}/${section.id}`);
      }
      this.lessonSections.splice(index, 1);
    },
    async changeSectionState({ state, id }) {
      try {
        this.loadingSection = true;
        const res = await this.$api.put(
          this.url.changeSectionState.replace('{id}', id),
          {
            id,
            state,
          },
        );
        if (res.error) {
          this.loadingSection = false;
          this.$toastr.e({
            title: 'Lỗi',
            msg: res.error.message,
          });
          return;
        }
        await this.getLessonSections();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message,
        });
      } finally {
        this.loadingSection = false;
      }
    },
    onUpload() {
      this.isImage = ['image/jpeg', 'image/png'].includes(this.file.type);
      this.isImageValid = this.file.size <= 512000;
      if (this.isImage && this.isImageValid) {
        return (this.preview = URL.createObjectURL(this.file));
      }
      return (this.preview = null);
    },
    onClickReturnButton() {
      this.moveToListPage();
    },
    onClickAddLessonSection() {
      this.modalType = MODAL_TYPE.CREATE;
      this.selectedSection = {
        status: 2,
      };
      this.openLessonSectionEditModal();
    },
    showPopupChangeStatus(state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          state ? 'Active' : 'Inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            this.onClickToggleStatusButton(state);
          }
        }.bind(this),
      );
    },
    onClickToggleStatusButton(state) {
      if (this.lessonId) {
        this.$api
          .put(this.url.changeState.replace('{id}', this.lessonId), {
            id: this.lessonId,
            state: state,
          })
          .then((res) => {
            if (res.error) {
              this.$toastr.e({
                title: 'Lỗi',
                msg: res.error.message,
                preventDuplicates: true,
              });
              return;
            }
            this.loadStatusData();
          })
          .catch((error) => {
            this.$toastr.e({
              title: 'Lỗi',
              msg: error.message,
              preventDuplicates: true,
            });
          });
      }
    },
    onAddTag(tag) {
      let newTag = {
        text: tag,
        value: this.$uuid.v1(),
      };
      this.form.tags.push(newTag);
      this.tagOptions.push(newTag);
      this.newTagsOptions.push(newTag);
    },
    getQuizList(level, size) {
      return this.$api.get(this.url.getQuiz, {
        params: {
          lessonLevelId: level,
          count: size,
        },
      });
    },
    getQuestionNumOptions(val) {
      let numArr = [];
      if (val) {
        for (let i = 0; i < val; i++) {
          numArr.push({
            text: i + 1,
            value: i + 1,
          });
        }
      }
      return numArr;
    },
    onClickSaveButton() {
      if (this.isCreateMode) {
        this.createLesson();
      } else {
        this.editLesson();
      }
    },
    async createLesson() {
      let coverId = this.file
        ? await this.uploadImage(this.file)
        : this.form.coverId;
      let params = {
        ...(coverId && { coverId: coverId }),
        code: this.form.code,
        name: this.form.name,
        description: this.form.description,
        lessonModuleId: this.form.module.value,
        type: this.form.type.value,
        status: this.form.status.value,
        lessonLevelId: this.form.level?.value,
        order: this.form.order?.value,
        isEnabledRating: this.form.activeRating,
        lessonTagMappings: this.getTagsParams(),
        minCompletePercent: this.form.minCompletePercent,
        quizLessons: this.getQuizParams(
          this.originalQuestionList,
          this.form.questionList,
        ),
      };
      this.loading = true;
      this.$api
        .post(this.url.create, params)
        .then((res) => {
          if (res.error) {
            this.$toastr.e({
              title: 'Lỗi',
              msg: res.error.message,
              preventDuplicates: true,
            });
            return;
          }
          let promiseArr = this.lessonSections.map(async (section) => {
            let imageId = section.imageFile
              ? await this.uploadImage(section.imageFile)
              : null;
            let videoUrl = section.fileVideo
              ? await this.uploadVideo(section.fileVideo)
              : null;
            let sectionParams = {
              code: section.code,
              name: section.name,
              lessonId: res.data.id,
              ...(imageId && { imageId: imageId }),
              imageTitle: section.imageTitle,
              type: section.type,
              status: section.status,
              firstContent: section.firstContent,
              secondContent: section.secondContent,
              videoAddressLink: videoUrl || section.videoUrl,
              videoDescription: section.videoTitle,
              audioAddressLink: section.audioUrl,
              audioDescription: section.audioTitle,
              quizLessonSections: section.questionList?.map((q) => {
                return {
                  quizId: q.id,
                  modelStatus: 3,
                };
              }),
              // classify: Number(this.form.type.value),
            };
            return this.$api.post(this.url.createSection, sectionParams);
          });
          Promise.all(promiseArr)
            .then(() => {
              this.moveToListPage();
            })
            .catch((error) => {
              this.$api.delete(this.url.delete.replace('{id}', res.data.id));
              this.$toastr.e({
                title: 'Lỗi',
                msg: error?.message,
                preventDuplicates: true,
              });
            });
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error.message,
            preventDuplicates: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async editLesson() {
      let coverId = this.file
        ? await this.uploadImage(this.file)
        : this.form.coverId;
      let params = {
        id: this.lessonId,
        ...(coverId && { coverId: coverId }),
        code: this.form.code,
        name: this.form.name,
        description: this.form.description,
        lessonModuleId: this.form.module.value,
        type: this.form.type.value,
        status: this.form.status.value,
        lessonLevelId: this.form.level?.value,
        order: this.form.order?.value,
        isEnabledRating: this.form.activeRating,
        lessonTagMappings: this.getTagsParams(),
        minCompletePercent: this.form.minCompletePercent,
        quizLessons: this.getQuizParams(
          this.originalQuestionList,
          this.form.questionList,
        ),
      };
      this.loading = true;
      this.$api
        .put(`${this.url.edit}/${this.lessonId}`, params)
        .then((res) => {
          if (res.error) {
            this.$toastr.e({
              title: 'Lỗi',
              msg: res.error.message,
              preventDuplicates: true,
            });
            return;
          }
          // let promiseArr = this.lessonSections
          //   .filter((section) => !section.id)
          //   .map(async (section) => {
          //     let imageId = section.imageFile
          //       ? await this.uploadImage(section.imageFile)
          //       : null;
          //     let sectionParams = {
          //       code: section.code,
          //       name: section.name,
          //       lessonId: res.data.id,
          //       ...(imageId && { imageId: imageId }),
          //       imageTitle: section.imageTitle,
          //       type: section.type,
          //       status: section.status,
          //       firstContent: section.firstContent,
          //       secondContent: section.secondContent,
          //       videoAddressLink: section.videoUrl,
          //       videoDescription: section.videoTitle,
          //       audioAddressLink: section.audioUrl,
          //       audioDescription: section.audioTitle,
          //       quizLessonSections: section.questionList?.map((q) => {
          //         return {
          //           quizId: q.id,
          //           modelStatus: 3,
          //         };
          //       }),
          //     };
          //     return this.$api.post(this.url.createSection, sectionParams);
          //   });
          // Promise.all(promiseArr)
          //   .then(() => {
          //     this.moveToListPage();
          //   })
          //   .catch((error) => {
          //     this.$toastr.e({
          //       title: 'Lỗi',
          //       msg: error?.message,
          //       preventDuplicates: true,
          //     });
          //   });
          this.moveToListPage();
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error.message,
            preventDuplicates: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTagsParams() {
      let tagParams = [];
      const tagParamsPush = (array, status, type = 1) => {
        array.forEach((tag) => {
          let params = {
            ...(type && {
              lessonId: this.lessonId,
              lessonTagId: tag.value,
            }),
            tag: {
              name: tag.text,
            },
            modelStatus: status,
          };
          tagParams.push(params);
        });
      };

      //Get common tags of two tags array => Status: 0 (Unchanged)
      let commonTags = intersectionWith(
        this.originalTags,
        this.form.tags,
        isEqual,
      );
      //Get tags only in original tags array => Status: 2 (Deleted)
      let uniqueOriginalTags = differenceWith(
        this.originalTags,
        this.form.tags,
        isEqual,
      );
      //Get tags only in current tags array
      let uniqueCurrentTags = differenceWith(
        this.form.tags,
        this.originalTags,
        isEqual,
      );
      let existedTags = differenceWith(
        uniqueCurrentTags,
        this.newTagsOptions,
        isEqual,
      ); // Status: 3 (new mapping)
      let createdTags = intersectionWith(
        uniqueCurrentTags,
        this.newTagsOptions,
        isEqual,
      ); // Status: 3 (new tag)

      tagParamsPush(commonTags, 0);
      tagParamsPush(uniqueOriginalTags, 2);
      tagParamsPush(existedTags, 3);
      tagParamsPush(createdTags, 3, 0);

      return tagParams;
    },
    getQuizParams(originalList, currentList) {
      let unchangedQuiz = intersectionWith(
        originalList,
        currentList,
        (a, b) => a.id === b.id,
      ).map((quiz) => {
        return {
          quizId: quiz.id,
          modelStatus: 0,
        };
      });
      let removedQuiz = differenceWith(
        originalList,
        currentList,
        (a, b) => a.id === b.id,
      ).map((quiz) => {
        return {
          quizId: quiz.id,
          modelStatus: 2,
        };
      });
      let newQuiz = differenceWith(
        currentList,
        originalList,
        (a, b) => a.id === b.id,
      ).map((quiz) => {
        return {
          quizId: quiz.id,
          modelStatus: 3,
        };
      });
      return [...unchangedQuiz, ...removedQuiz, ...newQuiz];
    },
    async loadStatusData() {
      let { data } = await this.$api.get(
        `${this.url.prepare}/${this.lessonId}`,
      );
      this.form.status = this.getSelectedValueDropdown(data.lessonStates);
      const sections = data.lessonSections;

      this.lessonSections = sections.map((section) => {
        return {
          code: section.code,
          id: section.id,
          name: section.name,
          type: section.type,
          status: section.status,
          firstContent: section.firstContent,
          secondContent: section.secondContent,
          imagePreview: section.image?.url,
          imageTitle: section.imageTitle,
          videoUrl: section.videoAddressLink,
          videoTitle: section.videoDescription,
          audioUrl: section.audioAddressLink,
          audioTitle: section.audioDescription,
          questionList: section.quizLessonSections || [],
          // classify: section.classify,
          order: section.order,
        };
      });
      // this.lessonSections = this.originalLessonSections.filter(
      //   (el) => el.classify == this.form.type.value,
      // );
    },
    async loadPrepareData() {
      try {
        this.loading = true;
        let res = this.lessonId
          ? await this.$api.get(`${this.url.prepare}/${this.lessonId}`)
          : await this.$api.get(this.url.prepare);
        let data = res.data;

        // load menu options
        this.levelOptions = this.getDropdownOptions(data.lessonLevels);
        this.moduleOptions = this.getDropdownOptions(data.lessonModules);
        this.stateOptions = this.getDropdownOptions(data.lessonStates);
        this.tagOptions = this.getDropdownOptions(data.lessonTags);
        this.typeOptions = this.getDropdownOptions(data.lessonTypes);

        // load lesson data
        this.form.code = data.code;
        this.form.coverId = data.coverId;
        this.form.description = data.description;
        this.form.name = data.name;
        this.orders = this.getQuestionNumOptions(data.orderHighest);
        this.form.order = this.orders.find(
          (order) => order.value == data.order,
        ) || {
          value: data.orderHighest,
          text: data.orderHighest,
        };
        this.form.activeRating = data.isEnabledRating;
        this.preview = data.image?.url;
        this.form.level = this.getSelectedValueDropdown(data.lessonLevels);
        this.form.module = this.getSelectedValueDropdown(data.lessonModules);
        this.form.status = this.getSelectedValueDropdown(data.lessonStates);
        this.form.type = this.typeOptions.find(
          (type) => type.value == data.type,
        );
        let selectedTags = data.lessonTags
          .filter((item) => item.selected === true)
          .map((item) => {
            return {
              text: item.text,
              value: item.value,
            };
          });
        this.form.tags = selectedTags.length ? selectedTags : [];
        this.originalTags = [...this.form.tags];

        // load quiz
        this.form.minCompletePercent = data.minCompletePercent;
        if (this.form.level) {
          let quizRes = await this.$api.get(
            `${this.url.detail}/${this.lessonId}`,
          );
          let quizLessons = quizRes.data.quizLessons;
          this.form.questionList = quizLessons.map((q) => q.quiz) || [];
          this.originalQuestionList = [...this.form.questionList];
          let selectedQuestionNum = quizLessons ? quizLessons.length : 0;
          this.form.selectedQuestionNum = selectedQuestionNum
            ? this.getQuestionNumOptions(quizLessons.length).find(
                (num) => num.value === selectedQuestionNum,
              )
            : null;
        }

        // load lesson sections
        if (data.lessonSections) {
          this.lessonSections = uniqBy(
            [...data.lessonSections, ...this.lessonSections],
            'id',
          ).map((section) => {
            return {
              code: section.code,
              id: section.id,
              name: section.name,
              type: section.type,
              status: section.status,
              firstContent: section.firstContent,
              secondContent: section.secondContent,
              imagePreview: section.image?.url,
              imageTitle: section.imageTitle,
              videoUrl: section.videoAddressLink,
              videoTitle: section.videoDescription,
              audioUrl: section.audioAddressLink,
              audioTitle: section.audioDescription,
              questionList: section.quizLessonSections || [],
              // classify: section.classify,
              order: section.order,
            };
          });
          // this.lessonSections = this.originalLessonSections.filter(
          //   (el) => el.classify == this.form.type.value,
          // );
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async getLessonSections() {
      try {
        const { error, data } = await this.$api.get(
          `${this.url.prepare}/${this.lessonId}`,
        );

        if (error) {
          return this.$toastr.e({
            title: 'Lỗi',
            msg: error.message,
            preventDuplicates: true,
          });
        }

        const sections = data.lessonSections;

        this.lessonSections = sections.map((section) => {
          return {
            code: section.code,
            id: section.id,
            name: section.name,
            type: section.type,
            status: section.status,
            firstContent: section.firstContent,
            secondContent: section.secondContent,
            imagePreview: section.image?.url,
            imageTitle: section.imageTitle,
            videoUrl: section.videoAddressLink,
            videoTitle: section.videoDescription,
            audioUrl: section.audioAddressLink,
            audioTitle: section.audioDescription,
            questionList: section.quizLessonSections || [],
            // classify: section.classify,
            order: section.order,
          };
        });
        // this.lessonSections = this.originalLessonSections.filter(
        //   (el) => el.classify == this.form.type.value,
        // );
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error.message,
          preventDuplicates: true,
        });
      }
    },
    getDropdownOptions(array) {
      return array.map((item) => {
        return {
          text: item.text,
          value: item.value,
        };
      });
    },
    getSelectedValueDropdown(array) {
      let selectedValue = array.find((el) => el.selected === true);
      return selectedValue
        ? {
            text: selectedValue.text,
            value: selectedValue.value,
          }
        : null;
    },
    selected(value, formProperty, optionsArr) {
      this.form[formProperty] = value
        ? optionsArr.find((el) => el.value == value)
        : null;
      if (this.form[formProperty]) {
        this.$validator.reset();
      }
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.onClickSaveButton();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
          preventDuplicates: true,
        });
        return null;
      }
      return data.id;
    },
    async uploadVideo(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_VIDEO_FILE',
        {
          file,
          containerName: CONTAINER_FILE_NAME.LESSON,
        },
      );
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
          preventDuplicates: true,
        });
        return null;
      }
      return data;
    },
    async uploadAudio(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_AUDIO_FILE',
        {
          file,
          containerName: CONTAINER_FILE_NAME.LESSON,
        },
      );
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
          preventDuplicates: true,
        });
        return null;
      }
      return data;
    },
    selectedTypeLesson(value) {
      if (value == 3) return;

      // this.lessonSections = this.originalLessonSections.filter(
      //   (el) => el.classify == value,
      // );

      if (this.isMandatoryMode || this.isOptionalMode) {
        new Sortable(this.$refs.sortableSections, {
          draggable: '.sortable',
          handle: '.sortable',
          onEnd: this.dragReorder,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.lesson-edit-page {
  .lesson-edit-page__body {
    .lesson-edit-page__body__card {
      height: 100%;

      .lesson-edit-page__body__card__quiz {
        .lesson-edit-page__body__card__quiz__title {
          font-weight: bold;
          margin-bottom: 16px;
        }

        .lesson-edit-page__body__card__quiz__btn-info,
        .lesson-edit-page__body__card__quiz__btn-info:hover,
        .lesson-edit-page__body__card__quiz__btn-info:active {
          background-color: #def2f1;
          border-color: #def2f1;
          color: #008479;
        }
      }
    }

    .image-upload {
      width: 120px;
      height: 120px;
      background-color: #f3f6f9;
      box-shadow: 0px 4px 4px 0px #000000 10%;
      position: relative;
      margin: auto;
    }

    .text-error {
      color: #ff5756;
      text-align: center;
      margin-top: 20px;
    }

    .pictureInput {
      position: relative;
    }

    .icon-edit {
      z-index: 10;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 18px);
      padding: 2px 6px;
      box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      width: fit-content;
      background-color: #fff;
    }
  }

  .lesson-edit-page__dropdown-filter {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
